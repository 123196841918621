import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import { MdCallEnd, MdCall } from "react-icons/md";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import {
  BsThreeDotsVertical,
  BsFillChatDotsFill,
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill
} from "react-icons/bs";
import { VideoOptions } from "./videoOptions";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useLocation, useNavigate } from "react-router";
import { tab } from "../../Utilities/responsive";

const socket = io("https://steadmed-apis.herokuapp.com");

export const VideoCall = () => {
  ////////////////////////////////////////////////
  ///////////////////////////////////////videocall////////////
  const [stream, setStream] = useState(null);
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [video, setVideo] = useState(true);
  const [audio, setAudio] = useState(true);
  const [hasOpened, setHasOpened] = useState(false);
  const [input, setInput] = useState("");
  const [call, setCall] = useState(null);
  const [me, setMe] = useState(null);
  const [name, setName] = useState("");
  const [doctorArray, setDoctorArray] = useState([]);
  const [doctorSocket, setDoctorSocket] = useState("");
  const [justOpened, setJustOpened] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { role, patientId, doctorId } = location.state;

  const SendDrId = () => {
    if (call === null && callAccepted === false) {
      socket.emit("add_user", { doctor_id: doctorId });
    }
  };

  const GetDoctors = () => {
    if (doctorSocket === "" && callAccepted === false) {
      socket.on("get_users", (data) => {
        setDoctorArray(data);
      });
    }
  };

  useEffect(() => {
    if (role === "patient") {
      const isFirstTime = sessionStorage.getItem("call");
      if (!isFirstTime) {
        sessionStorage.setItem("call", true);
        window.location.reload();
      }
    }
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true
      })
      .then((currentStream) => {
        setStream(currentStream);
        myVideo.current.srcObject = currentStream;
      });

    if (role === "doctor") {
      setInterval(() => {
        SendDrId();
      }, [2000]);
    } else {
      setInterval(() => {
        GetDoctors();
      }, [2000]);
    }
    socket.on("me", (data) => {
      setMe(data);
    });
    socket.on("callEnded", (data) => {
      setCallEnded(true);
      setCallAccepted(false);
      connectionRef.current?.destroy();
      if (role === "patient") {
        setDoctorArray([]);
        setDoctorSocket("");
      } else {
        setCall(null);
      }
    });
  }, []);

  useEffect(() => {
    socket.on("callUser", ({ from, name: callerName, signal }) => {
      setCall({ isReceivedCall: true, from, name: callerName, signal });
    });
  }, [call]);

  const answerCall = () => {
    setCallAccepted(true);
    setJustOpened(true);
    const peer = new Peer({ initiator: false, trickle: false, stream });
    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: call.from });
    });
    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    setCallAccepted(true);
    setJustOpened(true);

    const peer = new Peer({ initiator: true, trickle: false, stream });
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name
      });
    });
    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);
      setJustOpened(true);
      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    setCallEnded(true);
    setCallAccepted(false);
    socket.disconnect();
    connectionRef.current?.destroy();
    navigate(-1);
  };

  const toggleVideo = () => {
    const videoTrack = stream
      .getTracks()
      .find((track) => track.kind === "video");
    if (videoTrack.enabled) {
      videoTrack.enabled = false;
      setVideo(false);
    } else {
      videoTrack.enabled = true;
      setVideo(true);
    }
  };
  const toggleAudio = () => {
    const audioTrack = stream
      .getTracks()
      .find((track) => track.kind === "audio");
    if (audioTrack.enabled) {
      audioTrack.enabled = false;
      setAudio(false);
    } else {
      audioTrack.enabled = true;
      setAudio(true);
    }
  };

  useEffect(() => {
    if (call?.isReceivedCall && call?.signal && role === "doctor") {
      answerCall();
    }
  }, [call]);

  ////call doctor function/////

  useEffect(() => {
    if (
      doctorArray.length !== 0 &&
      role === "patient" &&
      callAccepted === false
    ) {
      doctorArray.map((data) => {
        if (data.doctor_id === doctorId) {
          setDoctorSocket(data.socket_id);
        }
      });
    }
  }, [doctorArray, doctorSocket, callAccepted, role, doctorId]);

  const isMobile = useIsMobile();
  //////////////////////////////options///////////////////////////////
  ////////////////////////////////////////

  const [videoOptionsActive, setVideoOptionsActive] = useState(false);

  const CallButtons = () => {
    return (
      <Options>
        <Middle>
          <Button active={audio} onClick={() => toggleAudio()}>
            {audio ? (
              <FaMicrophone size={isMobile ? 30 : 40} />
            ) : (
              <FaMicrophoneSlash color="white" size={isMobile ? 35 : 50} />
            )}
          </Button>
          <Button active={video} onClick={() => toggleVideo()}>
            {video ? (
              <BsFillCameraVideoFill size={isMobile ? 30 : 40} />
            ) : (
              <BsFillCameraVideoOffFill
                color="white"
                size={isMobile ? 30 : 40}
              />
            )}
          </Button>
          <Button active={true}>
            <BsFillChatDotsFill size={isMobile ? 30 : 40} />
          </Button>
          <EndCallButton onClick={() => leaveCall()}>
            <MdCallEnd size={isMobile ? 30 : 40} />
          </EndCallButton>
        </Middle>
        <Dots active={true}>
          <BsThreeDotsVertical
            size={isMobile ? 30 : 40}
            onClick={() => setVideoOptionsActive(true)}
          />
        </Dots>
      </Options>
    );
  };
  ////////////////////////////////////////////////////////
  //////////////////////////////
  ///////////////////////////////////
  const [activeVideo, setActiveVideo] = useState(1);
  const ToggleActiveVideo = () => {
    if (activeVideo === 1 && callAccepted) {
      setActiveVideo(2);
    } else {
      setActiveVideo(1);
    }
  };

  useEffect(() => {
    if (callAccepted && justOpened) {
      setActiveVideo(2);
      setHasOpened(true);
      setJustOpened(false);
    }
  }, [activeVideo, callAccepted, justOpened]);

  return (
    <Container>
      {role === "patient" && doctorSocket === "" && !callAccepted && (
        <InitialMessages>
          <h3>Waiting for Doctor...</h3>
        </InitialMessages>
      )}
      {role === "patient" && doctorSocket !== "" && callAccepted === false && (
        <InitialMessages>
          <h3>Call Doctor</h3>
          <CallButton
            onClick={() => {
              callUser(doctorSocket);
            }}
          >
            <MdCall size={isMobile ? 30 : 40} />
          </CallButton>
        </InitialMessages>
      )}
      {role === "doctor" && !call?.isReceivedCall && (
        <InitialMessages>
          <h3>Waiting for patient...</h3>
        </InitialMessages>
      )}
      {videoOptionsActive && <VideoOptions setActive={setVideoOptionsActive} />}
      <VideoDiv>
        {/* <MyBox active={1}>
          <MyVideo autoPlay playsInline ref={myVideo}></MyVideo>
        </MyBox> */}
        <MyBox onClick={() => ToggleActiveVideo()} active={activeVideo}>
          <MyVideo autoPlay playsInline ref={myVideo}></MyVideo>
        </MyBox>
        <UserBox onClick={() => ToggleActiveVideo()} active={activeVideo}>
          <UserVideo autoPlay playsInline ref={userVideo}></UserVideo>
        </UserBox>
      </VideoDiv>
      <CallButtons />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-color: black;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
`;
const VideoDiv = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  box-sizing: border-box;
  ${tab({
    height: "80%",
    position: "absolute",
    top: "0"
  })}
`;
const MyBox = styled.div`
  box-sizing: border-box;
  cursor: ${(props) => (props.active === 2 ? "pointer" : "")};
  position: ${(props) => (props.active === 2 ? "absolute" : "relative")};
  right: ${(props) => (props.active === 2 ? "2rem" : "")};
  top: ${(props) => (props.active === 2 ? "2rem" : "")};
  z-index: ${(props) => (props.active === 2 ? "10" : "1")};
  width: ${(props) => (props.active === 2 ? "35rem" : "100%")};
  height: ${(props) => (props.active === 2 ? "22rem" : "100vh")};
  transition: all 0.1s ease-in-out;
  object-fit: cover;
`;
const UserBox = styled.div`
  box-sizing: border-box;
  cursor: ${(props) => (props.active === 1 ? "pointer" : "")};
  position: ${(props) => (props.active === 1 ? "absolute" : "relative")};
  right: ${(props) => (props.active === 1 ? "2rem" : "")};
  top: ${(props) => (props.active === 1 ? "2rem" : "")};
  z-index: ${(props) => (props.active === 1 ? "10" : "1")};
  width: ${(props) => (props.active === 1 ? "35rem" : "100%")};
  height: ${(props) => (props.active === 1 ? "22rem" : "100vh")};
  transition: all 0.2s ease-in;
  object-fit: cover;
`;
const MyVideo = styled.video`
  background-color: black;
  width: 100%;
  height: 100%;
`;
const UserVideo = styled.video`
  background-color: #c4c4c4;
  width: 100%;
  height: 100%;
`;

const Options = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 1000;
  bottom: 3rem;
  padding: 2rem;
  display: flex;
  width: 100vw;
  ${tab({
    padding: "2rem",
    bottom: "8rem"
  })}
`;
const Middle = styled.div`
  display: flex;
  width: 35%;
  margin-left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 2rem;
  ${tab({
    marginLeft: "0",
    transform: "none",
    width: "80%",
    padding: "0"
  })}
`;
const Button = styled.div`
  cursor: pointer;
  background-color: ${(props) => (!props.active ? "red" : "white")};
  height: 8rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  ${tab({
    height: "6.5rem",
    width: "6.5rem"
  })}
  :hover {
    transform: translateY(-0.5rem);
    box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
  }
  :active {
    transform: translateY(-0.2rem);
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.5);
  }
`;
const EndCallButton = styled(Button)`
  background-color: red;
  color: white;
`;
const CallButton = styled(Button)`
  background-color: #25d366;
  color: white;
  margin-left: 2rem;
`;
const Dots = styled(Button)`
  margin-left: auto;
`;

const InitialMessages = styled.div`
  z-index: 1000;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    color: white;
    font-size: 5rem;
    ${tab({
      fontSize: "3rem"
    })}
  }
`;
