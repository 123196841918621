import React from "react";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import useClickOutside from "../../hooks/useClickOutside";

export const VideoOptions = ({ setActive }) => {
  const videoOptionsRef = useRef();
  useClickOutside(videoOptionsRef, () => setActive(false));
  return (
    <Container ref={videoOptionsRef}>
      <Column>
        <h2>View Patient form</h2>
      </Column>
      <Column>
        <h2>View Patient history</h2>
      </Column>
      <Column>
        <h2>Prescribe medication</h2>
      </Column>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 100;
`;

const Column = styled.div`
  background: white;
  height: 2rem;
  padding: 1rem;
  cursor: pointer;
  text-align: center;
  h2 {
    font-size: 1.4rem;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
  :hover {
    background-color: #e7e7e7;
  }
`;
